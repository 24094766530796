<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-button type="warning" @click="adddialogVisible=true;">新增应用</el-button>
    <auto-table :DataList="list" :option="option">
      <el-table-column label="操作">
        <template slot-scope="{ row }">
            <el-button type="primary" size="small" @click="$router.push({name:'appdownload',query:{id:row.id}})">详情</el-button>
        </template>
      </el-table-column>
    </auto-table>

    <!--  -->
    <el-dialog title="新增应用" :visible.sync="adddialogVisible" width="30%">
        <div>
          <el-form
            class="demo-form-inline"
            label-width="120px"
            label-position="left"
          >
            <el-form-item label="应用名称">
              <el-input v-model="name" placeholder="应用名称"></el-input>
            </el-form-item>
            <el-form-item label="发布平台">
              <el-input v-model="platform" placeholder="如：ios,android"></el-input>
            </el-form-item>
            
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="adddialogVisible = false">取 消</el-button>
          <el-button
            style="background-color: #ff7e00; color: #ffffff"
            type="primary"
            @click="add"
            >确 定</el-button
          >
        </span>
      </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      nav: { firstNav: "app管理", secondNav: "应用列表" },
      adddialogVisible:false,
      name:'',
      platform:'',
      option: [
        {
          name: "id",
          value: "id",
        },
        {
          name: "应用",
          value: "name",
        },
        {
          name: "平台",
          value: "platform",
        },
        {
          name: "版本",
          value: "version",
        },
      ],
    };
  },

  mounted() {
    this.getAppList();
  },

  methods: {
    add() {
      if(!this.name || !this.platform){
        this.$message("请填写应用信息")
        return
      }

      this.$axios({
        method: "post",
        url: "message/systemVersion/insert",
        data: {
          name: this.name,
          platform: this.platform,
          is_new:true,
          version:'1.0.0',
          status:2,
          type:1,
          user_id:0,
          iteration_content:'',
          url:''
        },
      }).then((res) => {
          if(res.data.code == 0){
            this.$message(res.data.message)
            this.adddialogVisible = false
            this.getAppList()
          }else{
            this.$message(res.data.message)
          }
      });
    },
    getAppList() {
      this.$axios({
        method: "get",
        url: "message/systemVersion/queryListPage",
      }).then((res) => {
        this.list = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>